import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import leaf from "../../Assets/Projects/leaf.png";
import emotion from "../../Assets/Projects/emotion.png";
import editor from "../../Assets/Projects/codeEditor.png";
import chatify from "../../Assets/Projects/chatify.png";
import suicide from "../../Assets/Projects/suicide.png";
import bitsOfCode from "../../Assets/Projects/blog.png";
import emre_web from "../../Assets/Projects/emre_web.png"
import emre_web2 from "../../Assets/Projects/emre_web2.png"
import remindify from "../../Assets/Projects/remindify.png";


function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          Üzerinde çalıştığım <strong className="purple">Çalışmalar </strong>
        </h1>
        
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={emre_web}
              isBlog={false}
              title="Heypena"
              description="Heypena bir proje yönetim uygulamasıdır. Bu uygulamada bir proje oluşturup bu projedeki takım arkadaşlarınıza görevlendirme yapabilirsiniz. Yaptığınız görevlendirmenin durumunu kanban üzerinden rahatlıkla takip edebilirsiniz."
              ghLink="https://github.com/soumyajit4419/Chatify"
              demoLink="https://chatify-49.web.app/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={emre_web2}
              isBlog={false}
              title="Garantili Evim"
              description="Bir emlak satış platformu olan Garantili Evim projesi, kullanıcıların evlerini satın alabileceği bir platformdur. Kullanıcılar, evlerini satabilir, ev arayabilir ve evler hakkında detaylı bilgi alabilirler."
              ghLink="https://github.com/soumyajit4419/Bits-0f-C0de"
              demoLink="https://blogs.soumya-jit.tech/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={remindify}
              isBlog={false}
              title="Remindify"
              description=" Remindify, kullanıcıların hosting ve domain gibi süreli işlemlerini takip etmelerine yardımcı olur. Kullanıcılar, süreli işlemlerini ekleyebilir, düzenleyebilir ve silebilir. Ayrıca, kullanıcılar, süreli işlemlerinin sona erme tarihlerine göre sıralanmış bir liste görüntüleyebilirler."
              ghLink="https://github.com/soumyajit4419/Editor.io"
              demoLink="https://editor.soumya-jit.tech/"              
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;

import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/avatar.svg";
import Tilt from "react-parallax-tilt";
import {
  AiFillGithub,
  AiOutlineTwitter,
  AiFillInstagram,
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
             KENDİMİ<span className="purple"> TANITAYIM </span>
            </h1>
            <p className="home-about-body">
            Yazılım geliştirmeyi ve girişimciliği seven bir makina mühendisi adayıyım.
              <br />
              <br />
              <br />
             Web üzerine geliştirmeler yaparken genellikle &nbsp;
              <i>
                <b className="purple">React </b>&nbsp; ve &nbsp;
                <b className="purple">
                  Tailwindcss &nbsp;
                </b> teknolojilerini kullanmaktayım.
              </i>
              <br />
              <br />
              
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={myImg} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
          <br />
          <br />
          <br />
            <h1>BANA BU PLATFORMLARDAN ULAŞABİLİRSİNİZ</h1>
            <p>
            Benimle gönül rahatlığıyla <span className="purple">bağlantı </span>kurup görüşebilirsiniz.
            </p>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://tr.linkedin.com/in/mehmet-emre-%C3%BCny%C4%B1lmaz-02563028a"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.instagram.com/emreunyilmaz/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <AiFillInstagram />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
